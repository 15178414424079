import PropTypes from 'prop-types';
import React from 'react';

const PortfolioSection = ({ children }) => {
  return (
    <section className="section-wrap-sm bg-light">
      <div className="container">
        <div className="row">
          <div id="portfolio-grid" className="works-grid grid-1-col clearfix">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

PortfolioSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PortfolioSection;
