import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import PropTypes from 'prop-types';
import React from 'react';

const PortfolioItem = ({ title, image, right, children }) => {
  return (
    <div className={'work-item' + (right ? ' align-right ' : '')}>
      <div className="work-container">
        <div className="work-img">
          <div className="bg-img-holder">
            <BackgroundImage
              Tag="div"
              className="bg-img"
              {...convertToBgImage(image)}
              preserveStackingContext={true}
            >
              <GatsbyImage image={image} alt={title} />
            </BackgroundImage>
          </div>
          <div className="work-description">
            <h3 className="uppercase">{title}</h3>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

PortfolioItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  right: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PortfolioItem;
